import React from "react"
import PropTypes from "prop-types"

import Button from "../components/button"
import logo from "../images/redsneakers.png"


const Header = ({ siteTitle }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "1rem 1rem",
    }}
  >


      <div>
        <a href="https://www.redsneakers.org"><img src={logo} alt="Red Sneakers for Oakley logo" /></a>
      </div>
    <h1 style={{ textAlign: "center" }}>We Are Dedicated to Raising Awareness About the Dangers of Food Allergies</h1>
    <a href="https://www.redsneakers.org/checkout/donate?donatePageId=5e59b76617c29a501a0032e9&utm_source=fb&utm_medium=lp"><Button>Donate Now</Button></a>
    <p style={{ textAlign: "center", maxWidth: 800 }}>
As an independent nonprofit, we rely on supporters like you to make our work possible. Your donation, large or small, ensures that we may continue to grow our awareness and educational programs. We are saving lives, one day at a time. </p>
<p>Thank you for your support.</p>
    </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
